/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
	return (
		<section id="footer" className="footer">
			<div className="container">
				<div className="row footer__main-wrapper">
					<div className="footer__logo col-12">
						<img
							alt=""
							src="https://seiturju.sirv.com/Chatbiz/logo-footer-white.svg"
							className="img-fluid footer__logo--image g-image"
						/>
					</div>
					<div className="footer__wrapper col-sm-12 col-md-8">
						<div className="footer__nav">
							<div className="footer__nav__items">
								<h5 className="footer__nav__items--title">About</h5>
								<a
									href="https://linkedin.com/company/chatbiz-id/"
									target="_blank"
									rel="noreferrer"
									className="footer__nav__items--link"
									id="mtFooterCompany"
								>
									Company
								</a>
								<a
									href="https://chatbiz.id/blog"
									target="_blank"
									rel="noreferrer"
									className="footer__nav__items--link"
									id="mtFooterBlog"
								>
									Blog
								</a>

								<a
									href="https://dailysocial.id/post/chatbot-chatbiz"
									target="_blank"
									rel="noreferrer"
									className="footer__nav__items--link"
									id="mtFooterDailySocial"
								>
									DailySocial
								</a>
							</div>
							<div className="footer__nav__items">
								<h5 className="footer__nav__items--title">Clients</h5>

								<Link
									to="https://chatbiz.id/#client"
									className="footer__nav__items--link"
									id="mtFooterOurCLient"
								>
									Our Client
								</Link>
								<a
									href="https://chatbiz.id/blog/kategori/portofolio"
									target="_blank"
									rel="noreferrer"
									className="footer__nav__items--link"
									id="mtFooterPortofolio"
								>
									Portofolio
								</a>
							</div>
							<div className="footer__nav__items">
								<h5 className="footer__nav__items--title">Others</h5>
								<a
									href="#"
									target="_blank"
									rel="noreferrer"
									className="footer__nav__items--link"
									id="mtFooterPartnership"
								>
									Partnership
								</a>
								<a
									href="#"
									target="_blank"
									rel="noreferrer"
									className="footer__nav__items--link"
									id="mtFooterMediaKit"
								>
									Media Kit
								</a>
								<a
									href="#"
									target="_blank"
									rel="noreferrer"
									className="footer__nav__items--link"
									id="mtFooterInvestment"
								>
									Investment
								</a>

								<Link
									to="https://chatbiz.id/kebijakan-privasi"
									className="footer__nav__items--link"
									id="mtFooterKebijakanPrivasi"
								>
									Kebijakan Privasi
								</Link>
							</div>
						</div>
						<div className="footer__copyright mt-auto">
							<h6 className="font-weight-normal mb-0">© 2020 Chatbiz.id. All Rights Reserved.</h6>
						</div>
					</div>
					<div className="col-sm-12 col-md-4">
						<div className="footer__info">
							<h5 className="footer__info__title">PT Pemuda Inovasi Teknologi</h5>
							<p className="footer__info__text">
								Jl. Gelap Nyawang No.1, Lb.
								<br />
								Siliwangi, Kecamatan <br />
								Coblong, Kota Bandung, <br />
								Jawa Barat 40132
							</p>
						</div>
						<div className="footer__info__sns">
							<a
								href="https://wa.me/6289522028530"
								target="_blank"
								rel="noreferrer"
								className="footer__info__sns--link"
								id="mtFooterLinkWA"
							>
								<img
									alt=""
									src="https://seiturju.sirv.com/Chatbiz/whatsapp-white.svg"
									className="footer__info__sns--image g-image"
								/>
							</a>
							<a
								href="https://instagram.com/chatbiz.id"
								target="_blank"
								rel="noreferrer"
								className="footer__info__sns--link"
								id="mtFooterLinkIG"
							>
								<img
									alt=""
									src="https://seiturju.sirv.com/Chatbiz/instagram-white.svg"
									className="footer__info__sns--image g-image"
								/>
							</a>
							<a
								href="https://linkedin.com/company/chatbiz-id/"
								target="_blank"
								rel="noreferrer"
								className="footer__info__sns--link"
								id="mtFooterLinkLinkedin"
							>
								<img
									alt=""
									src="https://seiturju.sirv.com/Chatbiz/linkedin-white.svg"
									className="footer__info__sns--image g-image"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Footer;
