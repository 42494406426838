/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import useScrollDirection from '../../../hooks/useScrollDirection';

const Navbar = ({ setSideBar }) => {
	const { currentY } = useScrollDirection();

	return (
		<nav
			id="navbar"
			className={
				currentY > 70
					? 'navbar navbar-light bg-light navbar-expand-lg navbar__is-fixed'
					: 'navbar navbar-light bg-light navbar-expand-lg'
			}
		>
			<div className="container">
				<div
					role="button"
					id="burger"
					className="d-block d-lg-none navbar__burger"
					onClick={() => {
						setSideBar(true);
					}}
					onKeyDown={() => {
						setSideBar(true);
					}}
					tabIndex={0}
				>
					<img alt="" src="https://seiturju.sirv.com/Chatbiz/burger-white.svg" className="img-fluid g-image" />
				</div>
				<Link to="/" className="navbar-brand ml-auto ml-lg-0 mr-auto mr-lg-0" id="mtBtnNavbarLogo">
					<img
						alt=""
						src="https://seiturju.sirv.com/Chatbiz/chatbiz-tech.svg"
						className="img-fluid g-image"
					/>
				</Link>
				<div id="nav-collapse" className="navbar-collapse collapse">
					<ul className="navbar-nav ml-auto">
						<li className="nav-item">
							<a
								href="https://chatbiz.id/blog"
								rel="noreferrer"
								target="_blank"
								className="nav-link"
								id="mtBtnNavbarPortofolio"
							>
								Blog
							</a>
						</li>
						<li className="nav-item">
							<a
								href="https://glints.com/id/companies/chatbiz-id/b54b8538-2bf2-44bc-8f60-a12885a0b530"
								rel="noreferrer"
								target="_blank"
								className="nav-link"
								id="mtBtnNavbarWhitePaper"
							>
								Jobs
							</a>
						</li>
						<li className="nav-item">
							<a
								rel="noreferrer"
								target="_blank"
								className="nav-link btn btn-white"
								href="https://www.linkedin.com/company/chatbiz-id/"
								id="mtBtnNavbarRequestDemo"
							>
								About Us
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

Navbar.propTypes = {
	setSideBar: PropTypes.func.isRequired,
};

export default Navbar;
