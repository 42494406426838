/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

const SideBar = ({ isSideBar, setSideBar }) => {
	return (
		<>
			<div id="overlay" className={isSideBar ? 'overlay active' : 'overlay'} />
			<OutsideClickHandler
				onOutsideClick={() => {
					setSideBar(false);
				}}
			>
				<nav id="sidebar" className={!isSideBar ? 'sidebar inactive' : 'sidebar'}>
					<ul className="list-unstyled sidebar__components">
						<li className="mb-3">
							<div className="d-flex w-100">
								<h5>Menu</h5>
								<div
									id="cross"
									className="ml-auto cursor-pointer"
									onClick={() => {
										setSideBar(false);
									}}
								>
									<img
										alt=""
										src="https://seiturju.sirv.com/Chatbiz/cross.svg"
										className="img-fluid g-image"
									/>
								</div>
							</div>
						</li>

						<li className="mb-4">
							<a
								href="https://chatbiz.id/blog"
								target="_blank"
								rel="noreferrer"
								id="mtBtnSideBarPortofolio"
							>
								Blog
							</a>
						</li>
						<li className="mb-4">
							<a
								href="https://glints.com/id/companies/chatbiz-id/b54b8538-2bf2-44bc-8f60-a12885a0b530"
								target="_blank"
								rel="noreferrer"
								id="mtBtnSideBarWhitePaper"
							>
								Jobs
							</a>
						</li>
						<li className="w-100">
							<a
								id="mtSideBarRequestDemo"
								className="btn w-100 btn-blue"
								rel="noreferrer"
								target="_blank"
								href="https://www.linkedin.com/company/chatbiz-id/"
							>
								About Us
							</a>
						</li>
					</ul>
				</nav>
			</OutsideClickHandler>
		</>
	);
};

SideBar.propTypes = {
	isSideBar: PropTypes.bool.isRequired,
	setSideBar: PropTypes.func.isRequired,
};

export default SideBar;
