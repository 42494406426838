/* eslint-disable object-shorthand */
import { useState, useEffect, useRef } from 'react';

const useScrollDirection = () => {
	const prevScrollY = useRef(0);

	const [goingUp, setGoingUp] = useState(false);
	const [currentY, setCurrentY] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollY = window.scrollY;
			setCurrentY(currentScrollY);

			if (prevScrollY.current < currentScrollY && goingUp) {
				setGoingUp(false);
			}
			if (prevScrollY.current > currentScrollY && !goingUp) {
				setGoingUp(true);
			}

			prevScrollY.current = currentScrollY;
		};

		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => window.removeEventListener('scroll', handleScroll);
	}, [goingUp]);

	return {
		goingUp: goingUp,
		currentY: currentY,
	};
};

export default useScrollDirection;
