/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-danger */
import React from 'react';

const Lists = () => {
	return (
		<>
			<section className="banner-home">
				<div className="wrapper">
					<div className="container">
						<div className="row banner-home__wrapper">
							<div className="col-md-12 mb-5">
								<div className="wrapper-banner">
									<div className="wrapper-banner__img">
										<img src="https://seiturju.sirv.com/Chatbiz/chatbiz-opensource.png" alt="open-source" />
									</div>
									<div className="wrapper-banner__text">
										<h1 className="banner-home__text--title">Open Source Project</h1>
										<h4 className="banner-home__text--description">
											We love open souce. Made for you by Chatbiz.id{' '}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="list-opensource-page" id="mt-list-opensource-page">
				<div className="container">
					<div className="row row-open-source">
						<div className="col-md-6 col-lg-4 mt-5 mb-5 list">
							<div className="image-container">
								<img
									src="https://seiturju.sirv.com/Chatbiz/opensource-wilayah-indonesia.png?format=webp"
									alt="cover"
								/>
							</div>
							<div className="text-container">
								<div className="text-wrapper">
									<h1 className="text-wrapper__title">Wilayah Indonesia</h1>
									<p className="text-wrapper__desc">
										Pencarian wilayah di Indonesia berdasarkan Provinsi, Kota/Kabupaten, Kecamatan dan
										Kelurahan/Desa.
									</p>
									<div className="text-wrapper__button-wrapper">
										<a
											href="https://github.com/chatbiz-id/wilayah-indonesia#readme"
											rel="noreferrer"
											target="_blank"
											id="mtBtnDownloadBuku1"
										>
											Github
										</a>
										<a
											href="https://www.npmjs.com/package/wilayah-indonesia"
											rel="noreferrer"
											target="_blank"
											id="mtBtnDownloadBuku1"
										>
											NPM
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4 mt-5 mb-5 comingsoon">
							<div className="wrapper">
								<img src="https://seiturju.sirv.com/Chatbiz/opensource-code-ico.svg" alt="coming-soon" />
								<div className="title">Segera Hadir</div>
								<div className="subTitle">Tunggu update terbaru project open source dari kami.</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-4 mt-5 mb-5 comingsoon hide-on-netbook">
							<div className="wrapper">
								<img src="https://seiturju.sirv.com/Chatbiz/opensource-code-ico.svg" alt="coming-soon" />
								<div className="title">Segera Hadir</div>
								<div className="subTitle">Tunggu update terbaru project open source dari kami.</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Lists;
