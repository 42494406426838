import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import 'typeface-montserrat';
import 'bootstrap/dist/css/bootstrap.css';
import '../assets/_homepage/sass/main.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar from '../components/_homepage/navbar';
import SideBar from '../components/_homepage/sidebar';
import ListOpenSource from '../components/_homepage/listOpenSource';
import Footer from '../components/_homepage/footer';
import Favicon from '../assets/img/favicon.ico';

const OpenSource = () => {
	const [isSideBar, setSideBar] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Helmet title="Chatbiz.id - Smart Virtual Assistant For Your Business">
				<meta
					name="keywords"
					content="Boost your sales with smart virtual assistant chatbot integrable with Whatsapp, LINE, Messenger, Telegram, or on your Website"
				/>
				<meta
					name="description"
					content="Boost your sales with smart virtual assistant chatbot integrable with Whatsapp, LINE, Messenger, Telegram, or on your Website"
				/>
				<meta name="author" content="Chatbiz" />
				<meta data-vue-tag="ssr" name="theme-color" content="#04589b" />
				<link rel="icon" href={Favicon} />
				<link rel="stylesheet" href="https://sibforms.com/forms/end-form/build/sib-styles.css" />

				<script src="https://sibforms.com/forms/end-form/build/main.js" />
			</Helmet>
			<div id="HomePageChatBiz" className="contentHomePage">
				<SideBar isSideBar={isSideBar} setSideBar={setSideBar} />
				<Navbar setSideBar={setSideBar} />
				<ListOpenSource />
				<Footer />
			</div>
		</>
	);
};

export default OpenSource;
